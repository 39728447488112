<template>
  <div class="flex min-h-screen flex-col bg-blue-500">
    <div class="w-full">
      <TnHeader />
      <div
        class="mx-auto min-h-[calc(100vh-var(--header-height))] max-w-5xl px-0 py-0 sm:px-6 sm:py-6 lg:px-8"
      >
        <slot />
      </div>
    </div>

    <TnFooter class="mt-auto" />
  </div>
</template>
